import React from "react"
import { Descriptions, Tag, List, Divider } from "antd"
import { Link } from "gatsby" // to query for image data
import { PostIndexPageProps } from "@richsoni/gatsby-theme-core/src/lib"
import Layout from "../../../components/headerFooterLayout"

const { CheckableTag } = Tag

export default ({
  posts,
  allTags,
  selectedTags,
  tagSizes,
  selectTag,
  deselectTag,
  location,
}: PostIndexPageProps) => (
  <Layout currentPath={location.pathname}>
    <div style={{ padding: "1em" }}>
      <List
        itemLayout="vertical"
        size="large"
        dataSource={posts}
        style={{ minHeight: "100vh" }}
        header={
          <Descriptions column={1}>
            <Descriptions.Item key={"Tags"} label="Tags">
              {allTags.map(tag => (
                <CheckableTag
                  style={{
                    border: "1px solid lightgray",
                    borderRadius: "10px",
                    cursor: "pointer",
                    marginBottom: ".5em",
                  }}
                  key={tag}
                  checked={selectedTags.includes(tag)}
                  onChange={checked =>
                    checked ? selectTag(tag) : deselectTag(tag)
                  }
                >
                  {tag} ({tagSizes[tag]})
                </CheckableTag>
              ))}
            </Descriptions.Item>
            <Descriptions.Item label="Posts Shown" key="Posts Shown">
              {" "}
              {posts.length}{" "}
            </Descriptions.Item>
          </Descriptions>
        }
        renderItem={item => {
          return (
            <Link
              to={item.slug}
              style={{ display: "flex", flexFlow: "row nowrap" }}
            >
              <div style={{ width: 250, overflow: "hidden", marginRight: 20 }}>
                {item.image}
              </div>
              <List.Item key={item.title}>
                <List.Item.Meta
                  title={item.title}
                  description={
                    <div>
                      {"Posted: " + item.date + " "}
                      {item.tags.map(c => (
                        <Tag key={c}>{c}</Tag>
                      ))}
                    </div>
                  }
                />
                {item.excerpt}
                <Divider />
              </List.Item>
            </Link>
          )
        }}
      />
    </div>
  </Layout>
)
